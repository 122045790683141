.toggle-button {
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  box-sizing: border-box;
  margin-left:10px
}

.toggle-button:focus {
  outline: none;
}

.toggle-button-line {
  width: 30px;
  height: 2px;
  background: white;
}

#toggle-home {
  display: none;
}