.result-example .spe {
  width: 60px;
  height: 60px;
}


.sub-title {
    color: #888;
    font-size: 14px;
    padding: 15px 0 9px 15px;
  }
  .not-square-grid .am-grid-icon {
    width: 40px;
    height: 60px;
  }

.live-sub-menu {
  text-align: center;
  padding-bottom: 70px;
}

.live-sub-menu .am-grid .am-flexbox .am-flexbox-item {
  float:left;
  cursor: pointer;
}

.live-sub-menu .am-grid .am-flexbox .am-flexbox-item img {
  height: 40px;
}

.live-content .live-header {
  padding: 10px 2px;
  padding-top: 10px;
}

.live-content div .live-header {
  padding-top: 10px;
  margin-bottom: 3px;
  padding-left: 10px;
}

.live-content div .live-footer-border {
  border-bottom: 2px solid #e2e2e2;
  display: inline-block;
  padding-bottom: 10px;
  padding-left: 10px;

}

.score-box {
  display: inline-block;
  padding: 2px;
  width: 22px;
  text-align: center;
  height: 23px;
  color: #fff;
}

.score-L {
  background: red;
}

.score-W {
  background: green;
}

.score-D {
  color: #000;
  background: #e2e2e2;
}

.key-event-list div {
  font-size: 13px;
}

/* .ant-back-footer {
  margin: 0 auto;
} */

.ant-back-footer {
  margin: 0 auto;
	/*width: 576px;*/
  width: 900%;
}

.ant-back-top {

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* margin: 0; */
  padding: 5px;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  /* right: 100px; */
  top: 0;
  z-index: 10;
  /* width: 40px; */
  height: 40px;
  cursor: pointer;
  /* width: 576px;
  text-align: right; */
  /* width: 576px; */

}

.ant-tooltip {

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
      padding-top: 0px;
  color: rgba(0,0,0,0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;

}


.ant-tooltip-hidden {

  display: none;

}


.ant-tooltip-placement-bottom, .ant-tooltip-placement-bottomLeft, .ant-tooltip-placement-bottomRight {

  padding-top: 8px;

}

.ant-tooltip-inner {

  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0,0,0,0.75);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);

}