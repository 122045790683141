:root {
  --secondary: #00C134;
  --primarycolor : #ED1C24;
  --mainfontcolor : #00008B;
}

.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
}

.side-drawer li {
  /*margin: 0.5rem 0;*/
  padding: 0.5rem 0;
 /* background: #e2e2e2e2;*/
  border-bottom: 1px solid #e2e2e2;
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  
}

.side-drawer a {
  text-decoration: none;
  color: #521751;
  font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #fa923f;
}

.drawerTop {
  min-height: 150px;
  background: #0F6F29;
  /*background: linear-gradient(180deg, rgba(237,33,58,1) 0%, rgba(147,41,30,1) 61%); */
  background: linear-gradient(180deg,#00C134 0%,#0F6F29 61%);
  /*filter: drop-shadow(0pt 3pt 10pt #00000059);
  background: linear-gradient(to bottom, rgb(160, 22, 51) 0%, rgb(160, 22, 51) 57%, var(--primarycolor) 100%, rgb(255, 255, 255) 100%);*/
}

/*@media screen and (min-width: 759px) {
  .side-drawer {
    display: none;
  }
}*/
