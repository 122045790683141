.color-white {
    color : #fff;
}

.color-gray {
    color : #e2e2e2;
}

.color-slight-gray {
    background : #f2f3f5;
}

.color-dark-yellow {
    color : yellow;
}
.color-dark-gray {
    color : rgb(136, 136, 136);
}

.color-black {
    color : #000 !important;
}

.color-dark-blue {
    color : #100a4a !important;
}

.color-blue {
    color: rgb(48, 70, 228);
}


.color-red {
    color : red;
}

.color-yellow {
    color : rgb(199, 154, 38);
}

.color-gold {
    color : #FFDF00 !important;
}

.color-green {
    color : #149337;
}

.color-green-light {
    color : #00C134;
}

.bg-second {
    background:  #006290;
}



.bg-white {
    background: #fff;
}

.bg-slight-gray {
    background : #f2f3f5;
}

.bg-light-gray {
    background: #f6f6f6 !important;
}

.bg-gray {
    background: #e2e2e2;
}

.bg-gray-light {
    background: #eee7e7;
}

.bg-green-light {
    background: #b9ffc7 !important
}

.bg-blue {
    background: rgb(48, 70, 228);
}

.bg-yellow {
    background : #FFFF00;
}

.bg-red {
    background : #FF0000;
}

.bg-yellow-dark {
    background: #8B8000;
}

.bg-green-light {
    background : #00C134;
}

.bg-blue-light {
    background:  #add8e6;
}

.bg-orange-light {
    background:   #FFD580;
}

.bg-purple {
    background:  #800080;
}

.btn-blue {
    color: #fff !important;
    background: rgb(48, 70, 228) !important;
}

.btn-version{
    background-color: #03a531;
    width: 200px;
    color: azure;
    border-radius: 15px;
    padding: 15px;
}

