.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.logo {
  display: block;
}

.logo a {
  display: inline-block;
}

.logo a b{
  font-weight: bold;
  font-size: 20px;
}

.medium-logo {
  height: 200px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  /* font-size: large; */
  margin-bottom: 100px;
}


.am-list-header {
  margin: 20px 0px 20px 0px;
}

.image-slider .slider-slide {
  /* vertical-align: text-bottom; */
  /* justify-content: center;
  align-items: center; */
}



.swiper-paragraph {
  /* margin-top: 24%; */
  height: 100%;
  vertical-align: middle;
  color: #fff;
  /* background: #000; */
  background: rgba(0, 0, 0, 0.3);
  padding-left: 5px;
  padding-top: 5px;
}

.swiper-vieo-pagination.swiper-pagination {
  text-align: right;
}


.league-menu div:first-child {
  text-align: left !important;
}

.league-menu div {
  /* padding: 10px;
  margin : 10px; */
  padding: 10px 0px;
  text-align: center;
}

.league-menu div a {
  color: #000;
  text-decoration: none;
  font-size: 15px;
}

.league-menu div a.active {
  border-bottom: 3px solid green;
}



.league-price-table table {
  font-weight: bold;
  /* margin: 15px; */
  /* margin-top: 40px; */
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  /* text-align: center; */
  width: 100%;
}

.league-price-table table th {
  color: #149337;
  font-size: 17px;
  border-bottom: 1px solid #e2e2e2;
}

.league-price-table table td {
  padding: 5px 0px;
  border-bottom: 1px solid #e2e2e2;
}

.league-price-table table a {
  color : #00C134;

}
.league-price-table table a:hover {
  color : blue;

}

.league-gem-table{
  margin-top: 10px;
}
.league-gem-table table{
  font-weight: bold;
  /* margin: 15px; */
  /* margin-top: 40px; */
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  /* text-align: center; */
  width: 50%;
  /* border:1px solid green; */
  /* margin:auto; */
}
.league-gem-table table td{
  padding:10px;
  text-align:center;
  border-radius: 5px;
  /* box-shadow:blue; */
  box-shadow: 2px 3px 1px 1px #e2e2e2;

 
}
.league-gem-table table tr{
  padding:10px;
  text-align: center;
  background-color: #169236;
  border-radius: 10px;
  /* border: 1px solid blue; */
  color: white;
}
.live-table{
  width: 100%;
  margin: auto;
  border-radius: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-top: 20px;
}
  
.flex-between{
  display: flex;
  justify-content: space-between;
  
}

.flex-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.version-box{
  background-color: white;
  border-radius:15px;
  padding: 20px;
  text-align: center;
  margin-top:5%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.tip-odd-row {
  /* padding:30px 10px; */
  /*background:linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),  url('/img/bg-odds.png');
  background-size: cover;
  background-position: center;*/
  padding-top: 20px;
  padding-bottom: 10px;
}

.tip-odd-title {
  font-size: 21.0667px;
  width: 100%;
  display: block;
  padding-top: 10px;
  padding-bottom: 5px;
}

.tip-odd-handicap {
  font-size:15px;
}

.tip-table  {
  color: #149337;
  font-weight: bold;
  border-radius: 10px;
  padding: 10px 0px;
  font-size: 16px;
  border-collapse: collapse;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.tip-table td {
  /* padding: 5px; */
  padding: 5px 5px 5px 10px;
  padding-bottom: 7px;
  /*border-bottom: 1px solid #e2e2e2;*/
  border-bottom: 1px solid #000;
}

.tip-table tr:last-child  td{
  border-bottom: none !important;
}

/*.tip-table tr td {
  text-align: center;
}*/
/*.tip-table tr td:nth-child(2) , .tip-table tr td:nth-child(3) {
  text-align: center;
}*/

tbody:nth-child(even)  tr td {
  background: #eee7e7;
}

.countdown-block div {
  padding: 0px !important;
  margin-top: 10px !important;
}

.countdown-block div span{
  font-size: 20px !important;
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

ul {
  list-style-type: none;
  padding: 0;
}

.menu ul {
  background-color: #222;
  margin: 0;
}

.menu li {
  font-family: sans-serif;
  font-size: 1.2em;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #888;
}
 
.menu a {
  text-decoration: none;
  color: #fff;
  display: block;
}


.menu-item {
  padding: 0 10px;
  margin: 5px 3px;
  user-select: none;
  cursor: pointer;
  border: none;
}

.menu-item a {
  color: #000;
  text-decoration: none;
  font-size: 15px;
  /* user-select: none; */
  user-select: none;
  border: none;
  display: block;
}


.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}


.App-title {
  font-size: 1.5em;
}

.am-list-header {
  margin: 20px 0px 20px 0px;
}

.image-slider .slider-slide {
  /* vertical-align: text-bottom; */
  /* justify-content: center;
  align-items: center; */
}

.image-slider  .slider-slide a p {
  margin-top: 25% !important;
  height: 100%;
  vertical-align: text-bottom;
  color: #fff;
  /* background: #000; */
  background: rgba(0, 0, 0, 0.3);
  padding: 3px;
}



.league-list-header {
  border: 2px solid #e2e2e2;
  border-radius: 20px !important;
  background: #e2e2e2;
  min-height: 27px;
  /* margin-top: -20px; */
  /* box-shadow: 2px 3px 1px 1px #e2e2e2; */

}

.league-list-header img {
  margin-left: 4px;
  height: 35px;
  border: 1px solid #fff;
  vertical-align: middle;
  border-radius: 40px;
  padding: 2px;

}

.league-list-header span {
  margin-left: 15px;
  color: #149337;
  font-weight: bold;
  font-size: 16px;
}

.league-box-paragraph {
  padding: 12px 10px 10px 10px;
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  margin-top: -20px;
  border-top: none;
  border-right: none;
  align-items: center;
  min-height: 50px;
  border-top-left-radius : none !important;
  transform-style: preserve-3d;
}

.league-box-paragraph b {
  font-size: 21px;
}

/* .list-content a div:first-child :nth-child(odd) {
  background: #f9f9f9;
} */
/* .league-box-paragraph {
  padding: 12px 10px 10px 10px;
  border: 2px solid #e2e2e2;
  border-top-color: rgb(226, 226, 226);
  border-top-style: solid;
  border-top-width: 2px;
  margin-top: -20px;
  border-top: none;
  /* display: flex; */
  /* align-items : center;
  min-height: 50px; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* vertical-align: middle; */
/* } */ 

.league-box-paragraph .row {
  margin-top: 20px;
}


/* .league-box-paragraph .grid {
  clear: both;
  float: left;
} */

.list-header {
  border: 2px solid #e2e2e2;
  border-radius: 20px !important;
  width: 270px;
  background: #fff;
  box-shadow: 2px 3px 1px 1px #e2e2e2;
}

.list-item {
  display: flex; 
  padding: 5px 0px;
}

.list-item i, .list-item span {
  color: #5f5353;
}


.live-detail-header {
  /* background: #149337; */
    background-image: none;
    background-image: -webkit-radial-gradient(circle, #10ff53,#097527);
    background-image: -o-radial-gradient(circle, #10ff53,#097527);
    background-image: radial-gradient(circle, #10ff53,#097527);
    padding: 37px 0px 25px 0px;
    margin-bottom: 30px;
    color: #fff;
    background-position: center;
    background-image: url('/img/green-bg.png');
    background-repeat: no-repeat;
    height: 170px;
    background-size: cover;

}

.league-menu div:first-child {
  text-align: left !important;
}

.league-menu div {
  /* padding: 10px;
  margin : 10px; */
  padding: 10px 0px;
  text-align: center;
}

.league-menu div a {
  color: #000;
  text-decoration: none;
  font-size: 15px;
}

.league-menu div a.active {
  border-bottom: 3px solid green;
}

.App-footer {
  bottom: 0;
  position: relative;
  background-color: #fff;
  color: white;
  z-index: 100;
}


.App-footer-inner {
  text-align: center;
  display: inline-flex;
  border-top: 1px solid #e2e2e2;
  /* padding-top: 6px; */
}

.App-footer-inner div {
  width: 100%;
  text-align: center;
  float:left;
}

.footer-inner div div {
	text-align: center;
}

.App-footer-inner span {
  color: #3f3c3c;
}

.am-grid-item-content a div  {
  padding-top : 7px;
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

ul {
  list-style-type: none;
  padding: 0;
}

.menu ul {
  background-color: #222;
  margin: 0;
}

.menu li {
  font-family: sans-serif;
  font-size: 1.2em;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid #888;
}
 
.menu a {
  text-decoration: none;
  color: #fff;
  display: block;
}


.menu-item {
  padding: 0 10px;
  margin: 5px 3px;
  user-select: none;
  cursor: pointer;
  border: none;
}

.menu-item-wrapper  a {
  color: #000;
  text-decoration: none;
  font-size: 14px;
  /* user-select: none; */
  /* -webkit-user-select: none;
  -khtml-user-drag: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none; */
  user-select: none !important;
  border: none;
   display: inline;
   /* pointer-events: none; */
}


.menu-item-wrapper  a:active, .menu-item a:hover {
  user-select: none !important;
  /* border: none; */
  /* pointer-events: none; */
}

.menu-item-wrapper  a .menu-item.active {
  border-bottom: 3px solid green;
}

/* .menu-wrapper--inner{
  transform: translate3d(2px, 0px, 0px) !important;
} */

.scroll-menu-arrow {
  padding: 10px;
  cursor: pointer;
}

.scroll-menu-arrow-left {
  padding: 10px 10px 10px 0px;
  cursor: pointer;
}

.scroll-menu-arrow-right {
  padding: 10px 0px 10px 10px;
  cursor: pointer;
}


/* .menu-item a:active, .menu-item a:hover {
  color: #000;
  text-decoration: none;
  font-size: 15px;
  user-select: none !important;
  border: none;
} */

.menu-item .active {
  border-bottom: 3px solid green;
}

/* .menu-wrapper--inner{
  transform: translate3d(2px, 0px, 0px) !important;
} */

.list-icon-circle {
  padding: 2px 10px 2px 4px;
  height: 35px;
  vertical-align: middle;
  border-radius: 40px;
  margin: 1px;
  width: unset !important;
}

.list-box {
  display: '-webkit-box';
  display: "flex"; 
  padding: '5px 0' 
}

.list-featured-img-hightlight {
  height: 64px !important;
  width: 90px !important; 
  margin-right: 15px !important
}


.list-featured-img {
  /* height: 64px !important;
  width: 90px !important;  */
  height: 64px !important;
  width: 90px !important; 
  margin-right: 15px !important;
  margin-left: 10px;
}

.list-item i {
  margin-left: 10px;
}
/* .list-featured-p {
  margin-bottom: 8px;
  padding-top:3px;
  line-height: 25px;
  font-size: 1em;
} */

.list-featured-p {
  margin-bottom: 0px;
  padding-top: 5px;
  line-height: 25px;
  font-size: 17px;
  font-size: 1em;
}

.list-featured-p span {
  color: #5f5353;
}

.list-footer {
  vertical-align: middle;
  font-size: 15px;
}

.list-footer span {
  display: inline;
  vertical-align: text-top;
  color: #00C134;
  font-style: normal;
  /* text-align: right; */
}

.list-footer img {
  padding-left: 5px;
  vertical-align: top;
  /* text-align: right; */
}



.list-footer i div {
  display:inline-block;
}

.fb-comments-count_fluid_span {
  margin: 0;
  padding: 0;
}

.live-score-header {
  margin-bottom: 20px;
}

.DayPicker {
  width: 100%;
  border: 1px solid #e2e2e2;
}

.DayPicker-wrapper {
  position: relative;
}

.DayPicker-Day {
  border-radius: none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  border-radius: 0% !important;
  background: #149337 !important;
}

.DayPicker-Month {
  margin: 0 !important;
  width: 100% !important;
}

.live-box{
  background: #e2e2e2;
  width: 120px;
  text-align: center;
  color: #149337;
  border-radius: 10px;
  cursor: pointer;
  height: 33px;
  line-height: 33px;
}

.live-box.active{
  background: #149337;
  width: 120px;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  height: 33px;
}

.calendar-color-box{
  vertical-align: middle;
}


.calendar-color-box-text{
  float: left;
  border: 1px solid #e2e2e2;
  display: block;
  width: 150px;
  text-align: center;
  color: #000;
  height: 33px;
  line-height: 33px;
  border-right: none;
  border-left: none;
  position: relative;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  cursor: pointer;
}

.calendar-color-box-text img {
  /* padding-top: 1px; */
  vertical-align: text-top;
  max-height: 33px;
}

.calendar-color-box .left{
  border: 1px solid #e2e2e2;
  display: block;
  width: 150px;
  text-align: center;
  color: #000;
  border-radius: 10px;
  height: 33px;
  line-height: 33px;
  border-right: none;
  border-left: none;
  position: relative;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-left: 1px solid #e2e2e2;
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
}

.calendar-color-box-img {
  background: #149337;
  width: 50px;
  text-align: center;
  color: #fff;
  border-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  height: 35px;
  /* line-height: 35px; */
  display: block;
  /* margin-left: 5px; */
}

.calendar-left-arrow {
  background: #149337;
  width: 35px !important;
  text-align: center;
  color: #fff !important;
  border-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  height: 35px;
  /* line-height: 35px; */
  display: block;
  /* margin-left: 5px; */
  cursor: pointer;
  line-height: 35px;
}

.calendar-right-arrow {
  background: #149337;
  width: 35px;
  text-align: center;
  color: #fff !important;
  border-radius: 15px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  height: 35px;
  /* line-height: 35px; */
  display: block;
  /* margin-left: 5px; */
  cursor: pointer;
  line-height: 35px;
}

.calendar-color-box-img img {
  vertical-align: middle;
}


/* .calendar-color-box img {
  padding-top : 10px;
} */

.league-box {
  margin-bottom: 30px;
}

.content-box span, .content-box p {
  /* font-size: 15px !important; */
  font-family: 'Myanmar3' !important;
  line-height: 1.8;
  /* text-align: justify; */
}

.live-sub-menu {
  text-align:  center;
}

.key-event-list {
  height: 50px;
  margin-top: 30px;
}

.holds-the-iframe {
  background:url(/img/loading.gif) center center no-repeat;
 }

 .iframe-video {
   height: 327px;
 }

 .download-apk-here {
   margin-top: 5px;
 }

 .download-apk-here a, .download-apk-button {
  font-family:"Arial",sans-serif;
  font-size: 13px;
  color: #fff;
  background:rgb(48, 70, 228);
  border-radius: 5px;
  padding: 5px 15px;
 }

 .bg-light-gray  tbody tr td:nth-child(2) {
   color : rgb(48, 70, 228);
 }

 .live-sub-menu .active {
   color : #00C134;
 }
 /* .bg-light-gray table tbody tr td:nth-child(3) {
    color : red;
 } */

.download-direct-apk-button {
  background: #00c134;
}

.download-apk-button, .download-direct-apk-button {

  font-family: Arial,sans-serif;
  font-size: 13px;
  color: #fff;
  border-radius: 5px;
  padding: 5px 15px;
  width: 100px;
  display: block;

}

.grids {
  padding-top: 10px ;
}

.setting-menu li {
  padding: 10px;
  font-size: 19px;
  font-size: 120%;
  background: #f9f6f6;
  border-bottom: 1px solid #fff;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  text-align: center;


}

.setting-menu ul a li img {
  height: 25px;
  margin-right: 13px;
  vertical-align: middle;
}

/* .setting-menu li::after {
  text-align: right;
  content:"\003e";
} */

.setting-menu li:hover {
  padding: 10px;
  /* font-size: 17px;
  font-size: 120%; */
  background: #149337;
  border-bottom: 1px solid #fff;
      border-bottom-color: rgb(255, 255, 255);
      border-bottom-style: solid;
      border-bottom-width: 1px;

}

.setting-menu a li:hover  {
  color : #fff;
}

.setting-menu a li  {
  font-size: 19px;
  color: #149337;
  font-size: 19px;
  color: #149337;
  border-radius: 15px;
  margin: 15px;
}

.setting-menu a li  span {
  float: right;
  font-size: 20px;
  color: #e2e2e2;
}

.mini-menu ul {
  border-radius: 10px;
}

.mini-menu ul li:first-child {
  border-top-left-radius: 10px;
}

.mini-menu li {
  padding: 13px;
  /* font-size: 17px;
  font-size: 120%; */
  background: #e2e2e2;
  border-bottom: 1px solid #fff;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-style: solid;
  border-bottom-width: 1px;

}

.mini-menu ul a li img {
  height: 20px;
  margin-right: 7px;
  vertical-align: middle;
}

/* .setting-menu li::after {
  text-align: right;
  content:"\003e";
} */

.mini-menu li:hover {
  /* padding: 10px; */
  /* font-size: 17px;
  font-size: 120%; */
  background: #149337;
  border-bottom: 1px solid #fff;
      border-bottom-color: rgb(255, 255, 255);
      border-bottom-style: solid;
      border-bottom-width: 1px;

}

.mini-menu a li:hover  {
  color : #fff;
}

.mini-menu a li  {
  /* font-size: 19px; */
  color: #149337;
}

.mini-menu a li  span {
  float: right;
  /* font-size: 20px; */
  color: #e2e2e2;
}

:root {
  --secondary: #00C134;
}

.media {
  /* background-color: var(--secondary); */
  padding: 20px 3px;
  color: #fff;
  border-radius: 0.3rem;
  margin-bottom: 5px;
  display: flow-root;
}

.sub-media {
  /* background-color: var(--secondary); */
  padding-left: 20px;
  color: #fff;
  border-radius: 0.3rem;
  margin-bottom: 5px;
  display: flow-root;
}

.live-footer-border div {
  font-size: 13px;
}

.secondsubmenu {
  display: inline-flex;
}

.secondsubmenu li {
  padding-right: 10px;
}

.info-indicator span {
  cursor: pointer;
  margin-left: 10px;
  border : 1px solid #e2e2e2;
  padding: 0px 3px 0px 3px;
  color: #000;
  background: #e2e2e2;
}

.detail-icon {
  width: 90px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.sslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sslider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .sslider {
  background-color: #2196F3;
}

input:focus + .sslider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .sslider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sslider.round {
  border-radius: 34px;
}

.sslider.round:before {
  border-radius: 50%;
}

.package-block {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  border: 1px solid #fff;
  margin: 0px 5px 5px 0px;
  border-radius: 10px;
  
}

.package-block.active {
  border: 1px solid #FFFF00;
}

.package-block:hover {
  background: #00c134;
}

.package-block:hover  b {
  color : #FFDF00 !important;
}

.tree-table td, .tree-table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid 
  #dee2e6;
}

.tree-table hr {
  color : #dee2e6;
}

.form-box {
  padding-top : 30px;
}

.team-div {
  clear: both;
  padding-bottom: 30px;
  display: inline-block;
}

.team-box:hover {
  background : #e2e2e2;
}

.team-box:hover .footer {
  color : #FFDF00;
}

.team-box {
  margin-right : 15px;
  margin-bottom: 15px;
}

.team-box .body {
  padding: 15px;
  background  : #e2e2e2;
}

.team-box .footer {
  padding: 5px 0px;
  color : #fff;
  background : #006290;
}

.video-js,.player-area ,.player-area  video  {
  width: 100% !important;
  background: #000 !important;
}

.avoid-clicks {
  cursor: not-allowed !important;
  /* pointer-events: none; */
}

.commentList {
  padding: 10px;
}

.commentList .media-body img, .commentList .sub-media img {
  display: list-item;
  width: 40%;
}

.top-btn {
  margin-top: 20px;
  margin-left: 4px;
}

.content-box img, .mde-preview img {
  clear: both;
  width: 80%;
  display: block;
}

.svg-icon[data-icon="image"] {
  display: none !important;
}

/* ::selection { background: transparent; border: none; }
::-moz-selection { background: transparent; border: none; } */

/* .video-react {
  padding-top: 0px !important;
}

.video-react-video {
  width: 100%;
} */

.gallery {
  background: #EEE;
}

.gallery-cell {
  width: 50%;
  height: 400px;
  margin-right: 10px;
  background: #8C8;
  counter-increment: gallery-cell;
}

/* cell number */
.gallery-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
