
::selection { background: transparent; }
::-moz-selection { background: transparent; }
a:-moz-focusring {
    color: transparent;
	text-shadow: 0 0 0 #000;
	/* border : none !important; */
}

input[type="submit"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/* input[type="radio"] {
    height:16px !important;
    -moz-appearance: radio !important;
    -webkit-appearance: radio !important;
    appearance: radio !important;
} */

/* Your App styles here */
a {
	text-decoration: none;
}


.navbar-inner {
	position: unset;
}

.block-title {
	overflow: unset;
}

.page {
	overflow: auto;
}

.App-header {
	/*background:#00C134 !important;*/
	background: linear-gradient(180deg,#00C134,#149336 61%);
	filter: drop-shadow(0 3pt 10pt #00000059);
	padding-bottom: 4px;

}

.App-header-inner , .App-footer-inner {
	margin: 0 auto;
	width: 100%;
	/*width: 576px;*/
	/* width: 499px; */

}

.page-content {
  margin: 0 auto;
  width: 90%;
}

.App-header-inner {
	/* padding: 8px 0px; */
	padding-top: 8px;
	display: flex;
	display: -ms-flexbox;
}

.page-content {
	overflow: visible;
	/*overflow-y: scroll !important;*/
	height: 90% !important;
	min-height: 500px;
	/*background: #fff;*/
	/*background: #fffde1;*/
}



.list-content {
	overflow: visible;
	/*overflow-y: scroll !important;*/
	height: 90% !important;
	/* min-height: 500px; */
	background: #fff;
}



.profile-circle {
	height: 30px;
	vertical-align: middle;
	border-radius: 30px;
}

.swiper-video-height, .swiper-video-height a {
  height: 400px !important;
}

.list-img-circle {
	height: 40px;
	vertical-align: middle;
	border-radius: 40px;
	margin: 1px;
	padding-right: 4px;
}

/* .list-img-circle {
    height: 18px;
    vertical-align: middle;
    border-radius: 18px;
    margin: 10px;
} */



.list-header span {
	margin-left: 5px;
	color: #000;
	font-weight: bold;
	text-decoration: none;
	/* display: block; */
	cursor: default;
}

.img-footer-circle {
	height: 25px;
    vertical-align: middle;
    border-radius: 25px;
    margin-bottom: 5px;
}


.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}



.navbar {
	background: #00C134 !important;
}

.block-title {
    margin: 10px 10px 1px !important;
}

.md .navbar:after {
	background: none;
}


.md .list .item-content {
	padding-left: 0px;
}


.App-footer {
	position: fixed;
    /* left: 0; */
    bottom: 0;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    /* box-sizing: border-box; */
    /* background:#00C134 !important; */
    /* display: block; */
    /* padding: 5px; */
}

.App-footer span {
	font-size: 9px;
	display: block;
}

.swiper-pagination-bullet-active {
	background: #00C134 !important;
}



.mde-header ul.mde-header-group li.mde-header-item {
	margin: 2px !important;
}
/* .mde-header ul.mde-header-group li.mde-header-item {
	margin: 0px 1px;
} */
@media (max-width: 845px) { 
	.App-footer-inner, .App-header-inner, .page-content, .list-content,.navbar-inner {
		width: 100% !important;
	}
}
@media (max-width: 575px) { 
	.App-footer-inner, .App-header-inner, .page-content, .list-content,.navbar-inner {
		width: 100% !important;
		overflow: hidden;
	}

	}

	@media (max-width: 400) { 
		.App-footer-inner, .App-header-inner, .page-content, .list-content,.navbar-inner {
			width: 100% !important;
			overflow: hidden;
		}
	
		}

@media (max-width: 400px) { 
	.page{
		width: 100%  !important;
		overflow: hidden;

	}
	.page-content{
		width: 100% !important;
		/* overflow: auto !important; */
	}
}




  
/* @media (max-width: 768px) { 

	@media only screen and (orientation:portrait)
    {
        .chlivechat
        {
            display: block;
        }
    }

    @media only screen and (orientation:landscape)
    {
        .chlivechat
        {
            display: none !important;
        }
    } 

} */

.customModal {
	padding: 0px !important;
	background: transparent !important;
}

.customModal button {
	z-index: 10;
	background : #fff !important;
}

@media (max-width:575px){
	
	.swiper-video-height, .swiper-video-height a {
	  height: 200px !important;
	}

	.mde-header .svg-icon {
		font-size: small;
	}
	
	.mde-header ul.mde-header-group {
		padding: 0px !important;
	}
	
	
	.navbar-inner {
		padding-right: 15px;
	}

	/* .page {
		overflow: auto !important;
	} */

	/* .page-content {
		overflow: auto;
	} */

	.logo {
		padding: 0px 0px 0px 0px;
	}

	.logo a {
		padding-left: 10px;
	}

	.main-content {
		padding: 0px 5px 0px 8px;
	}

	.page-contest {
		padding: 0px 0px 0px 30px;
	}

	/* .swiper-paragraph {
		margin-top: 20% !important;
	} */
	.calendar-color-box .left {
		width: 180px;
	}

	.live-detail-header {
		height: unset;
	}

	.live-box, .live-box.active {
		width: 90px !important;
	}

	.league-list-header span {
		margin-left: 10px !important;
	}

	.download-apk-here a {
		margin-right: 20px !important;
	}

	.App-header-inner {
		/* padding: 10px 0px !important; */
		padding-top: 8px;
	}

	/* .grids {
		padding-top : 30px !important;
	} */

	.grid-s-100 {
		width: 100% !important;
	}

	.list-featured-p {
		margin-bottom: 0;
		padding-top: 3px;
		line-height: 23px;
		font-size: 17px;
		font-size: 1em;
		padding-bottom: 3px;
	}

	/* .media {
		padding: 10px;
	} */
	
	/* .media .media-body {
		margin-left: 50px;
	} */
	
	.iframe-video {
		height: 200px !important;																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																	;
	}

	.top-btn {
		margin-top : 20px;
		margin-right : 5px;
	}

	
	.app-header-center {
		width: 59.99% !important;
	}

	.app-header-right {
		width: 29.99% !important;
	}

}


