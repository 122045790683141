@font-face {
    font-family:'Zawgyi-One';
    src: url('/font/zawgyi.woff') format('woff'), url('/font/zawgyi.ttf') format('ttf');
}

@font-face {
    font-family:'Myanmar3';
    /* src:local('Myanmar3'), url('https://mmwebfonts.comquas.com/fonts/myanmar3.woff') format('woff'), url('https://mmwebfonts.comquas.com/fonts/myanmar3.ttf') format('ttf'); */
    src: url('/font/myanmar3.woff') format('woff'), url('/font/myanmar3.ttf') format('ttf');
}

/* @font-face {
    font-family:'Pyidaungsu';
    src:local('Pyidaungsu'), url('/fonts/Pyidaungsu-2.1_Regular.woff') format('woff'), url('/fonts/Pyidaungsu-2.1_Regular.ttf') format('ttf');
}

@font-face {
    font-family:'Pyidaungsu';
    src:local('Pyidaungsu'), url('/fonts/Pyidaungsu-2.1_Bold.woff') format('woff'), url('/fonts/Pyidaungsu-2.1_Bold.ttf') format('ttf');
    font-weight:bold;
} */



*{
    font-family: "Lato","Myanmar3";
    font-size: medium;
    margin: 0;
    padding: 0;
}

a {
    color: #000;
}

.row {
    clear: both;
    width: 100%;
}

.clear {
    clear: both;
}

.left {
    float: left;
}

.right {
    float: right;
}

.text-center {
    text-align : center;
}

.text-right {
    text-align : right;
}

.text-left {
    text-align : left;
}

.v-align {
    vertical-align:  middle;
}

.grid {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.grid-5 {
    width: 4.99%;
}

.grid-10 {
    width: 9.99%;
}

.grid-15 {
    width: 14.99%;
}

.grid-20 {
    width: 19.99%;
}

.grid-25 {
    width: 24.99%;
}

.grid-30 {
    width: 29.99%;
}

.grid-35 {
    width: 34.99%;
}

.grid-40 {
    width: 39.99%;
}

.grid-45 {
    width: 44.99%;
}

.grid-33 {
    width: 33.33%;
}

.grid-50 {
    width: 49.99%;
}

.grid-60 {
    width: 59.99%;
}

.grid-70 {
    width: 69.99%;
}

.grid-80 {
    width: 79.99%;
}

.grid-90 {
    width: 89.99%;
}

.grid-100 {
    width: 100%;
}

.h-80 {
    height: 79.99%;
}

.h-70 {
    height: 69.99%;
}

.h-60 {
    height: 59.99%;
}

.h-20 {
    height: 19.99%;
}

.h-30 {
    height: 29.99%;
}

.h-40 {
    height: 39.99%;
}

.flag-text {
    padding: 0px 10px;
}

.table {
    border-collapse: collapse;
    width: 100%;
    padding: 100px;
    
}

.table td, .table tr {
    padding: 1px 10px;
}
.grid-container {
    display: grid;
    gap: 50px 100px;
    grid-template-columns: auto auto auto;
    min-height: 300px;
    /* background-color: #2196F3; */
    /* padding: 10px; */
  }
  
  .grid-item {
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    /* padding: 20px; */
    font-size: 30px;
    text-align: center;
  }

  .grid-item1{
    grid-row: 1 / span 3;
  }

  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 15px;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .container {
    padding: 2px 16px;
  }

.table td, .table th {
    padding: .55rem;
}

.strip-table {
    cursor: pointer;
    border-collapse: collapse;
    width: 100%;
    border: 2px solid #e2e2e2;
}

.strip-table thead tr  {
    background: #e2e2e2;
    color : #149337;
}

.strip-table tbody tr  {
    border-bottom: 1px solid #e2e2e2;
}

.strip-table tbody tr  td tr:last-child {
    border-bottom: 0px;
}

.strip-table tbody tr:hover  {
    color: #fff;
    background: #149337;
}

.strip-table tbody tr:hover td  {
    color: #fff;
}

.p-4{
    padding: 4px;
}

.pt-4{
    padding-top: 4px;
}

.pt-10{
    padding-top: 4px;
}

.pb-4{
    padding-bottom: 4px;
}
.pr-4{
    padding-right: 4px;
}

.pr-10{
    padding-right: 10px;
}

.m-4 {
    margin: 4px;
}

.m-5 {
    margin: 5px;
}

.mt-4 {
    margin-top: 4px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.bdr-right {
    /*border-right: 1px solid #e2e2e2;*/
    border-right: 1px solid #000;
}

.bdr-bottom {
    border-bottom: 1px solid #e2e2e2;
}

.bdr-top {
    border-top: 1px solid #e2e2e2;
}

.bdr-top-bottom {
    border: 1px solid #000;
/*    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;*/
}

.img-responsive {
    width: 100%;
    height: 100%;
}

/* .page-content img {
    width: 100%;
} */

/* .pluginSkinLight > div {
    width: 100% !important;
} */

.no-bg {
    background: #fff;
}
.bein{
    padding: 10px;
    background-color: #5C2C91;
    color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 20%;
    margin-left: 5px;

}
.super{
    padding: 10px;
    background-color: #070D17;
    color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 20%;
    margin-left: 5px;
}
.flex-sport{
    display: flex;
    justify-content: space-around;
}
div a.cont-wrap-link
{
    padding:0px; 
    margin:0px;
    display:block;
    text-decoration:none;
    font-size: 14px;
}

.tipo-box {
    text-align: center;
    /* background: blue; */
    /* border: 1px solid black; */
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 7px;
    border-radius: 20px;
    padding: 4;
    background-color: #c7c7c7;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.arrow-prev,.arrow-next {
    color : #00c134;
    font-weight: bold;
}

.selectDisable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    /* font-size: 1rem; */
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 5px;
}

.btn-small {
    display: inline-block;
    font-weight: 300;
    color: #212529;
    text-align: left;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .305rem .50rem;
    /* font-size: 1rem; */
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 5px;
}

.btn:active {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    /*border-color: #28a745;*/
}
.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    /*border-color: #6c757d;*/
    
}
.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    /*border-color: #f8f9fa;*/
}

.btn-yellow {
    color: #fff;
    background-color: rgb(199, 154, 38) ;
    /*border-color: rgb(199, 154, 38) ;*/
}

.btn-danger {
    color: #fff;
    background-color: #FF0000 ;
    /*border-color: #FF0000 ;*/
}

.btn:hover {
    opacity: 0.9;
    filter: alpha(opacity=90); /* For IE8 and earlier */
 }

button, select {
    text-transform: none;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
    line-height: calc(1.5em + .75rem + 2px);
}

.form-control {
    display: block;
    width: 100%;
    min-height: calc(1.5em + .75rem + 2px);
    /* padding: .375rem .75rem; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.round-img {
    border-radius: 50%;
}



.table-border {
    border: 1px solid #e2e2e2;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.table-border tr {
    padding-top: 20px;
    padding-bottom: 20px;
}

.responsive-table td, .responsive-table th {
    padding: .75rem;
}

.Spin {
    animation: App-logo-spin infinite 2s linear;
}

.comment {
    display: block;
    width: 90%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

}



.pointer {cursor: pointer;}

.align-middle {
    vertical-align: middle !important;
}

.no-bd {
    border: 0px !important;
}

.no-padding {
    width: 50px;
    padding: 0px !important;
}
.package-gem{
    background-color: #ffff;
    border-radius: 15px;
    margin-top: 20px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    /* display: flex;
    justify-content:space-between; */
}
.contest-box{
    margin-top:35px;
}
.package-box{
    background-color: #ffff;
    border-radius: 15px;
    margin-top: 20px;
    padding: 20px;
    
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; 
    /* height:200px; */
    text-align: center;
}
.package-box:hover{
    background-color: #e2e2e2;
}
.more-box{
    background-color: #ffff;
    border-radius: 15px;
    margin-top: 20px;
    padding: 20px;
    
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; 
    /* height:200px; */
    text-align: center;
}
.more-box:hover{
    background-color: #e2e2e2;
}
.flex{
    display: flex;
    justify-content:space-around;
    align-items: center;

}
/* .flex-item{
    align-items: center;
} */
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
    border-top: 1px solid 
    rgba(0,0,0,.1);
}

/* .box-shadow {
    -webkit-box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.75);
} */
.list-box-shadow {
    box-shadow: 2px 0px 5px 2px rgba(147, 138, 138, 0.25);
    -webkit-box-shadow: 2px 0px 5px 2px rgba(147, 138, 138, 0.25);
    margin-bottom: 10px;
}

.box-shadow {
    -webkit-box-shadow: 5px 0px 10px 2px rgba(0,0,0,0.25);
    /* box-shadow: 5px 1px 2px 0px rgba(0,0,0,0.25); */
    box-shadow: 5px 0px 10px 2px rgba(0,0,0,0.25);
    border-radius: 20px;
}

.font-small {
    font-size: 11px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (max-width:575px){
    .responsive-table td, .responsive-table th {
        padding: .75rem .2rem !important;
    }

}
@media(max-width:400px) {
    .grid-container {
        
        gap: 10px 10px;
        
        
    }
    .more-box {
        padding:10px;
    }
}

@media (max-width:500px){
    .grid-container {
       /* width:100%; */
       padding:0px;
       margin: 0px;
        grid-template-columns:  auto;
        width:100%;
        min-height: 700px;
        /* overflow: auto !important; */
    }
    .card{
        padding:0px;
        margin: 0px;
         grid-template-columns:  auto;
         width:100%;
         overflow: auto !important;
    }
    .card>.container{
        padding:0px;
        margin: 0px;
         grid-template-columns:  auto;
         width:100%;
         overflow: auto !important; 
    }

}
